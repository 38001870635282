<template>
  <div class="d-flex justify-center">
    <v-select
      style="max-width: 75px;"
      ref="countryInput"
      v-model="countryCode"
      @change="onChangeCountryCode"
      :items="allCountries"
      outlined
      dense
      hide-details
      return-object
    >
      <template v-slot:selection>
        <v-img height="18" :src="countryCode.flag" contain></v-img>
      </template>
      <template v-slot:item="data">
        <span style="max-width: 20px; min-width: 20px" class="mr-2">
          <v-img height="12" :src="data.item.flag" contain></v-img>
        </span>
        <span>{{ data.item.name }} {{ `+${data.item.callingCodes}` }}</span>
      </template>
    </v-select>
    <v-text-field ref="input" type="tel" v-model="phone" dense hide-details outlined>
    </v-text-field>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    defaultCountry: {
      type: String,
      default: () => 'IN',
    }
  },
  data() {
    return {
      allCountries: [],
      phone: '',
      activeCountry: { iso2: '' },
      countryCode: {},
    };
  },
  computed: {
    phoneObject() {
      return {
        phone: this.phone,
        iso2: this.activeCountry.iso2,
        dialCode: this.activeCountry.dialCode
      }
    }
  },
  watch: {
    activeCountry(value) {
      if (value && value.iso2) {
        this.$emit('country-changed', value);
      }
    },
  },
  mounted() {
    this.getCountriesList()
    // this.initializeCountry()
  },
  methods: {
    getCountriesList () {
      axios.get('https://restcountries.com/v2/all?fields=name,timezones,nativeName,flag,callingCodes').then(({ data }) => {
        this.allCountries = data
      })
    },
    choose(country, toEmitInputEvent = false) {
      this.activeCountry = country || this.activeCountry || {}
      if (this.phone && this.phone[0] === '+' && this.activeCountry.iso2) {
        // Attach the current phone number with the newly selected country
        // this.phone = PhoneNumber(
        //   this.phoneObject.number.significant,
        //   this.activeCountry.iso2,
        // ).getNumber('international');
      }
      if (toEmitInputEvent) {
        this.$emit('input', this.phoneText, this.phoneObject)
      }
    },
    onChangeCountryCode() {
      this.choose(this.countryCode, true);
    }
  }
}
</script>
